
<template>
       <div class="col-md-7 col-sm-7 mt-10 boxmain">
          <section class="container boxrouting">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('general.contact_us')}}
                </h2>
              </div>
            </div>
          </section>
          <section class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="service-detail-text">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="contact-form">
                        <form method="post" id="my_submit" @submit="onSubmit">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group mrb-15">
                                <input type="text" name="name" placeholder="Họ tên (*)" class="form-control" required="required">
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group mrb-15">
                                <input type="text" name="phone" placeholder="Điện thoại (*)" class="form-control" required="required">
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mrb-15">
                                <input type="email" name="email" placeholder="Email" class="form-control" required="required">
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group" style="margin-bottom: 0px">
                                <p style="margin-bottom: 0px">
                                  <strong>
                                    Vui lòng chọn phòng ban cần liên hệ
                                  </strong>
                                </p>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mrb-15 rating rating_lh">
                                <ul v-if="departments!=null">
                                  <li v-for="(d,k) in departments" :key="k">
                                    <input type="radio" name="departmentId" :value="d.id" :id="'good'+k"> <br />
                                    <label :for="'good'+k"><span>{{d.name}}</span></label>
                                  </li>
                                 
                                </ul>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mrb-15">
                                <textarea rows="3" name="content" placeholder="Nội dung tin nhắn" class="form-control" required=""></textarea>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group">
                                <div class="vcaptcha">
                                  <input class="form-control" placeholder="Vui lòng nhập mã bảo vệ bên dưới (*)" name="proctectcode" v-model="my_capcha" type="text" value="" data-bv-field="captcha" required="required">

                                </div>
                              </div>
                               <span class="vimage" v-if="capcha!=null">
                                <img class="lazy" :src="'data:image/jpeg;base64, '+capcha.captchBase64Data" style="float: left;">
                                

                                <div style="border:1px solid #ddd;float: left;padding: 4px; margin-left: 5px;" @click="reloadCapcha()">
                                    <img src="@/assets/refresh.png" />
                                 </div>

                                 <input type="hidden"  name="clientId" :value="capcha.clientId" />
                              </span>
                            </div>
                            <div class="col-lg-12 text-center">
                              <div class="form-group">
                                <button type="submit" name="submit" class="btn_news cs-btn-one btn-md btn-round btn-primary-color element-shadow" value="Send">GỬI NGAY</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <!-- Google Map Start -->
                      <div class="mapouter fixed-height">
                        <div class="gmap_canvas">
                          <iframe id="gmap_canvas"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.955503914295!2d106.70914021538391!3d10.814717192295564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175289741790d39%3A0x95362685e34cec2f!2zQuG6v24geGUgTWnhu4FuIMSQw7RuZw!5e0!3m2!1svi!2s!4v1521995883442"></iframe>

                        </div>
                      </div>
                      <!-- Google Map End -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-xl-6">
                      <div class="contact-block d-flex mrb-30">
                        <div class="contact-icon">
                          <i class="webex-icon-map1"></i>
                        </div>
                        <div class="contact-details mrl-20">
                          <h5 class="icon-box-title mrb-10">Bến xe miền Đông</h5>
                          <p class="mrb-0">292 Đinh Bộ Lĩnh, phường 26, Quận Bình Thạnh, Hồ Chí Minh</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-xl-6">
                      <div class="contact-block d-flex mrb-30">
                        <div class="contact-icon">
                          <i class="webex-icon-Phone2"></i>
                        </div>
                        <div class="contact-details mrl-20">
                          <h5 class="icon-box-title mrb-10">Số điện thoại - Hotline</h5>
                          <p class="mrb-0">(028) 899 1607 - 1900571292</p><br>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>


             <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t("question.name_question")}}

                  <a   
                      :href="$router.resolve({name: 'question',params:{locale:$i18n.locale}}).href"  
                     :title="$t('question.name_question')">
                    {{$t('news.see')}}
                  </a>
                </h2>

              </div>
            </div>
          </div>




          <div class="request-a-call-back pdb-md-30" v-if="question==null">
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 mrb-md-60">
                  <div class="faq-block">
                    <div class="accordion">

                      <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                      <div class="accordion-item">
                        <div class="accordion-header active">
                          <h5 class="title">{{$t('question.question')}} : <Skeleton></Skeleton></h5>
                          <span class="fa fa-plus"></span>
                        </div>
                        <div class="accordion-body">
                          <p>{{$t('question.answer')}}:
                           <Skeleton></Skeleton>
                          </p>
                        </div>
                      </div>
                      </SkeletonTheme>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="request-a-call-back pdb-md-30" v-else>
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 mrb-md-60">
                  <div class="faq-block">
                    <div class="accordion">

                      <div class="accordion-item" v-for="(q,k) in question" :key="k">
                        <div class="accordion-header " >
                          <h5 class="title" v-html="$t('question.question')+': '+sript_tag(q.name)" >

                             
                          </h5>
                          <span class="fa fa-plus"></span>
                        </div>
                        <div class="accordion-body">
                          <p v-html="$t('question.answer')+': '+sript_tag(q.answer)">
                          
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          </section>


        </div>


</template>




<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";


  import axios from 'axios';
export default {
  
 
  data() {
    return {
      

      myurl:window.my_url,
      my_data:null,
     departments:null,
      message:false,
      capcha:null,
      meta:{
        title: "Liên hệ",
        description: "Ý KIẾN GÓP Ý VỚI CHÚNG TÔI"
      },
      my_capcha:"",
      question:null
      
    };
  },
  metaInfo(){
     return {
        title:this.meta.title,
        description:this.meta.description
     }
  },
  components: {
    Skeleton,
    SkeletonTheme
    
    
    
  },
  created(){

     //s this.$i18n.locale
          var t1=  axios.get(window.my_api+"api/department/get-departments", {
                                 headers: {
                                   language: 'vn'
                                 }
                                })

              .then( (res)=>{
                  
                  if(res.status==200){

                       this.departments=res.data.data;
                      
                                            
                  }else {
                    console.log("ERROR",res);
                  }
                   

                });

               var t2=        axios.get(window.my_api+"api/question/get-home-questions",
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
                .then( (res)=>{           
                  if(res.status==200){
                      this.question=res.data.data.slice(0,6);//.filter(n => n.language==this.$i18n.locale);
                     
                      
                  }
                });

          Promise.all([t1,t2]).then(()=>{
                 this.is_loading=false;
                 this.reloadCapcha();
          });
      
  },

  methods:{
    sript_tag(string){
         string= string.replace(/<\/?[^>]+>/ig, " ").toString(); 

           return string;
    },
    reloadCapcha(){
         axios.post(window.my_api+"api/captchar/generate-captchars")
                  .then( (res)=>{
                  
                    if(res.status==200){

                         this.capcha=res.data.data;
                         //console.log(this.capcha);

                                                                   
                    }else {
                      console.log("ERROR",res);
                    }
                     

                });
    },
      onSubmit(event){
           event.preventDefault();
           if(this.my_capcha.toUpperCase()!=this.capcha.captchaCode.toUpperCase()){
              alert("Vui lòng nhập đúng capcha");
              this.reloadCapcha();
              return false;
           }
           var form_data = new FormData(document.getElementById('my_submit'));
           axios.post(window.my_api+"api/contact/create-or-update-contact",form_data)
                .then((res)=>{
                    if(res.status==200){

                      if(res.data.errors.length>0){
                          alert("Vui lòng điền đủ thông tin.");
                      }else {
                          document.getElementById('my_submit').reset();
                          alert(" Cảm ơn bạn đã liên hệ đến chúng tôi. Chúng tôi sẽ liên hệ đến bạn một cách sớm nhất. ");

                      }

                    }
                });
         
           return false;
      }
    
  }
}
</script>
